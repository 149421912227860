:root {
  --color-bg: hsl(0, 0%, 100%);
  --color-bg-light: hsl(0, 0%, 100%);
  --color-bg-dark: hsl(233, 8%, 19%);
}

[data-theme="dark"] {
  --color-bg: hsl(232, 11%, 15%);
  --color-bg-light: hsl(233, 8%, 19%);
}

.psbt-explainer {
  margin-top: 250px;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between;  */
}

#magic-bytes-explainer, #global-maps-explainer, #input-maps-explainer, #output-maps-explainer {
  border-radius: 12px;
  /* source: https://codyhouse.co/nuggets/beautiful-css-shadows */
  box-shadow: inset 0 0 0.5px 1px hsla(0, 0%, 100%, 0.1),
              /* 2. shadow ring 👇 */
              0 0 0 1px hsla(230, 13%, 9%, 0.075),
              /* 3. multiple soft shadows 👇 */
              0 0.3px 0.4px hsla(230, 13%, 9%, 0.02),
              0 0.9px 1.5px hsla(230, 13%, 9%, 0.045),
              0 3.5px 6px hsla(230, 13%, 9%, 0.09);
  
  background-color: var(--color-bg-dark);
  overflow-y: hidden;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 24px;

  max-width: 800px;

  /* source: https://codepen.io/brundolf/pen/dvoGyw 
   * https://css-tricks.com/using-css-transitions-auto-dimensions/ 
  */
  transition:height 0.3s ease-out;
}

[data-collapsed=true] {
	height: 12px;
  transition:height 0.3s ease-out;
}

.magic-bytes-explainer h3, .global-maps-explainer h3, .input-maps-explainer h3, .output-maps-explainer h3 {
  margin-left: 16px;
}

.magic-bytes-explainer h4, .global-maps-explainer h4, .input-maps-explainer h4, .output-maps-explainer h4 {
  margin-left: 16px;
}

/* .collapse {
  height: 4px;
  margin-bottom: 4px;
  transition: height 1000ms, margin-bottom 300ms;
} */

/* .expand {
  height: 100%;
  margin-bottom: 24px;
  transition: height 1000ms;
} */
