.psbt-magic{
  &-magic_bytes, &-head_separator {
    background: #62dd6aa8;
  }
}

// .psbt-magic-head_separator {
//   background:#b45959;
// }

.psbt-global {
  &-type-b\'\\x00\' {
    &-key {
      background:#5858a0;
    }
    &-val {
      background: #6586d4;
      overflow-wrap: break-word;
    }
  }

  &-type-b\'\\x01\' { // PSBT_GLOBAL_XPUB = 0x01
    &-key {
      background:#7458a0;
    }
    &-val {
      background: #6567d4;
    }
  }
}

[class$="-separator"] {
  background: #a349aa;
}

[class$="-len"] {
  background:#4c50d0;
}

.psbt-input-maps-- {
  &type-b\'\\x00\' { // PSBT_IN_NON_WITNESS_UTXO = 0x00	
    &-key, &-val {
      background: #699564;
    }
  }

  &type-b\'\\x01\' { // PSBT_IN_WITNESS_UTXO = 0x01	
    &-key {
      background: #5875a0;
    }
    &-val {
      background: #2b82ad;
    }
  }

  &type-b\'\\x02\' { // PSBT_IN_PARTIAL_SIG = 0x02	
    &-key {
      background: #cd5e5e;
    }
    &-val {
      background: #d94444;
    }
  }

  &type-b\'\\x03\' { // PSBT_IN_SIGHASH_TYPE = 0x03	
    &-key {
      background: #50aac6;
    }
    &-val {
      background: #2d9fc0;
    }
  }

  &type-b\'\\x04\' { // PSBT_IN_REDEEM_SCRIPT = 0x04	
    &-key, &-val {
      background: #d0ba30;
    }
  }

  &type-b\'\\x05\' { // PSBT_IN_WITNESS_SCRIPT = 0x05
    &-key, &-val {
      background: #9ece44;
    }
  }

  &type-b\'\\x06\' { // PSBT_IN_BIP32_DERIVATION = 0x06
    &-key, &-val {
      background: #62c990;
    }
  }

  &type-b\'\\x07\' { // PSBT_IN_FINAL_SCRIPTSIG = 0x07
    &-key {
      background: #6461c0;
    }
    &-val {
      background: #6560ce;
    }
  }
}

.psbt-output-maps-- {
  &type-b\'\\x02\' { // PSBT_OUT_WITNESS_SCRIPT = 0x02	
    &-key {
      background: #5875a0;
    }
    &-val {
      background: #2b82ad;
    }
  }
}

#highlighting {
  z-index: 0;
}