@media screen and (min-width: 320px) {
  .welcome-message h2 {
    font-size: .95em;
    margin-block-start: 0.43em;
    margin-block-end: 0.43em;
  }

  .welcome-message p {
    font-size: 1em;
  }

  .welcome-message li .text {
    font-size: 1.2em;
  }
}

@media screen and (min-width: 641px) {
  .welcome-message h2 {
    font-size: 1.2em;
    font-weight: bold;
    margin-block-start: 0.63em;
    margin-block-end: 0.63em;
  }
}

@media screen and (min-width: 961px) {
  .welcome-message h2 {
    font-size: 1.5em;
    font-weight: bold;
    margin-block-start: 0.83em;
    margin-block-end: 0.83em;
  }
}

@media screen and (min-width: 1025px) {

}

.welcome-message {
  margin-top: 250px;
}

ul li {
  margin-bottom: 12px;
}

.how-to li {
  list-style: inside;
}

/* ul li div.example-hex {
  max-width: 800px;
} */

.welcome-message {
  code {
    /* border: 1px solid red; */
    overflow-wrap: anywhere;
    font-size: larger;
  }

  .example-hex.explainer-val {
    button {
      background: #323232;
      margin: 8px 4px;
      border-radius: 4px;
    }
  }
}

.up-arrow {
  width: 45px;
  height: 45px;
  /* fill: white; */
}

.copy-icon {
  width: 16px;
  height: 16px;
  margin: 2px;
  fill: #ddd;
}