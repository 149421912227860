@media screen and (max-width: 319px) {
  .txn-input {
    font-size: small;
  }

  #editor, #highlighting {
    position: absolute;
    top: 40px;
  }
}

@media screen and (min-width: 320px) {
  .txn-column {
    margin: 0px 2px 12px 12px;
    flex-grow: 1;
  }

  .txn-input {
    font-size: small;
  }

  input[type="text"] {
    margin: 0px 2px;
    width: 72px;
  }

  #editor, #highlighting {
    position: absolute;
    top: 20px;
  }
}

@media screen and (min-width: 480px) {
  input[type="text"] {
    margin: 0px 4px;
    width: 160px;
  }
}

@media screen and (min-width: 640px) {
  .txn-column {
    margin: 0px 12px 12px 24px;
    flex-grow: 1;
  }

  .txn-input {
    font-size: medium;
  }

  input[type="text"] {
    margin: 0px 8px;
    width: 180px;
  }
}

@media (min-width:961px) {
  .txn-column {
    margin: 0px 24px 12px 32px;
    flex-grow: 1;
  }

  /* #editor, #highlighting {
    position: absolute;
    top: 20px;
  } */
}

label[for=txn] {
  font-weight:bold;
  text-transform: uppercase;
}

.saveButton {
  background-color: #191920;
  padding: 0px;
  border: 0px;
}

.saveIcon {
  fill: #dddddd;
  height: 24px;
  width: 24px;
  vertical-align: middle;
}

.txn-input {
  /* font-size: medium; */
  position: relative;
}

input[type="text"] {
  margin: 0px 8px;
  padding-block: 0px;
  padding-inline: 0px;
  border: 0px;
  background-color: #191920;
  font-size: medium;
  font-style: italic;
  color: rgb(160, 160, 160);
}

input[type="text"]:focus {
  font-style: normal;
}

#editor, #highlighting {
  /* Both elements need the same text and space styling so they are directly on top of each other */
  margin: 12px 5px;
  padding: 2px;
  border: 0;
  width: calc(100% - 12px);
  height: 220px;
  /* position: absolute;
  top: 20px; */
  left: 0;
  transition: left 300ms;
  overflow-y: scroll;
}

#editor, #highlighting, #highlighting * {
  /* Also add text styles to highlighting tokens */
  font-size: 15pt;
  font-family: monospace;
  line-height: 20pt;
}

#editor {
  z-index: 1;
  color: transparent;
  background: transparent;
  caret-color: white;
  resize: none;
  scrollbar-color: #ababab black;
  scrollbar-width: thin;
}

#editor:focus-visible {
  outline: 1px solid cornflowerblue;
}

#highlighting {
  /* overflow-x: clip; */
  border: 1px solid rgb(117, 117, 117);
}

/* style when cursor */
#editor:focus {

}

.match-version {
  background: #748cab;
}

.match-segwit {
  background: #3e5c76;
}

/* .match-num_inputs {
  /* background: #283f60; */
  /* background: #5a49c2; */
/* }  */

.match-inputs-txid {
  background: #457b9d;
}

.match-inputs-vout {
  background: #1372b6;
}

.match-inputs-script_sig {
  background: #6461c0;
}

.match-inputs-sequence {
  background: #d474d4;
  /* background: #d58787; */
}

[class^="match-witness-"] {
  background: #2a5fd2;
}

[class*="-num_"], [class*="-length"] {
  /* background: #362fc1; */
  background: #5a49c2;
}

/* .match-num_outputs {
  background: #d16868;
} */

.match-outputs-amount {
  /* background: #68d89b; */
  background: #3e8853; 
}

.match-outputs-scriptPubKey {
  background: #bb9f64;
}

.match-locktime {
  background: #85a040;
}

#highlighting {
  z-index: 0;
}