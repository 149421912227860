/* 
[class$=explainer] 
*/

ul {
  padding-inline-start: 0px;
}

ul li {
  list-style: none;
}

.magic-bytes-description {
  /* margin-left: 16px;
  margin-bottom: 12px; */
  margin: 8px 16px 12px 16px;
  max-width: 768px;
}