
.hex-value-container {
  display: flex;
  flex-direction: column;
  overflow:hidden;
  box-sizing: border-box;
  display: block;
  max-width: 800px;
}

.stuff {
  font-family: monospace;
  font-size: 12pt;
  word-wrap: break-word;
  margin: 12px 16px;
}