.txn-explainer {
  margin-top: 250px;
  display: flex;
  flex-direction: row;
  justify-content: space-between; 
}

.explainer-row {
  display: grid;
  grid-template-columns: 140px 180px 1fr;
  overflow-wrap: break-word;
}

.explainer-hex-value {
  font-size: 15pt;
  font-family: monospace;

  margin: 4px 8px;
  justify-self: end;
}

.explainer-key {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* align-items: center;
  gap: 10px; */
}

.explainer-val {
  overflow: hidden;
  margin: 4px 8px;
}

.key-name {
  font-size: 15pt;
  font-family: monospace;
  margin: 4px 8px;
}

.interpreted-val {
  font-size: 14pt;
  word-wrap: break-word;
}