body {
  margin: 0;
  background-color: #191920; /* #282c34; */
  font-size: medium; /*calc(10px + 2vmin); */
  color: white;
}

* { box-sizing: border-box; }

/* 282c34 */
/* .App-header {
  background-color: #191920; 
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
} */

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.menubutton span {
  display: block;
  width: 24px;
  height: 2px;
  margin-bottom: 5px;
  position: relative;
  
  background: #dddddd;
  border-radius: 3px;
  z-index: 1;
}

/* Header stuff */
nav {
  display: flex;
  align-items: center;
  color: white;
  margin-bottom: 16px;
  padding-right: 32px;
  justify-content: space-between;
}

.header-links {
  display: flex;
  flex-direction: row;
  gap: 16px;
}

* a {
  color: white;
}

/* Footer Stuff */
.footer {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.node-env {
  color: gray;
  margin: auto 4px;
  font-weight: bold;
}

/* Sliding Sidebar */
.sidebar-item>input {
  display: none;
}

/* TODO width 319 and lower */
@media screen and (max-width: 319px) {
  .nav .nav-title {
    font-size: .8em;
    margin-block-start: 0.33em;
    margin-block-end: 0.33em;
  }
}

@media screen and (min-width: 320px) {
  body:has(.sidebar-item > input:checked) .nav-right {
    width: 6.2rem;
  }

  nav {
    padding-right: 8px;
  }

  .nav-right h4, .header-links h4 {
    font-size: small;
  }

  .App {
    padding: 24px 4px 24px 4px;
  }

  .nav .nav-title {
    font-size: 1em;
    margin-block-start: 0.43em;
    margin-block-end: 0.43em;
  }
}

@media screen and (min-width: 641px) {
  .App {
    padding: 24px 12px 24px 12px;
  }

  nav {
    padding-right: 16px;
  }

  .nav-right h4, .header-links h4 {
    font-size: medium;
  }

  .nav .nav-title {
    font-size: 1.2em;
    font-weight: bold;
    margin-block-start: 0.63em;
    margin-block-end: 0.63em;
  }

  body:has(.sidebar-item > input:checked) .nav-right {
    width: 7rem;
  }
  
}

@media screen and (min-width: 961px) {
  .App {
    padding: 24px 24px 24px 24px;
  }

  nav {
    padding-right: 32px;
  }
  
  body:has(.sidebar-item > input:checked) .nav-right {
    width: 8rem;
  }
  .nav-right h4, .header-links h4 {
    font-size: medium;
  }

  .nav .nav-title {
    font-size: 1.5em;
    font-weight: bold;
    margin-block-start: 0.83em;
    margin-block-end: 0.83em;
  }
}

@media screen and (min-width: 1025px) {
  /* 
  nav .nav-title {
    font-size: 1.5em; 
    margin-block-start: 0.83em;
    margin-block-end: 0.83em;
  } 
  */
}

.nav-right {
  width: 1px;
  transition: width 300ms;
  overflow-x: hidden;
}

.nav-right h4 {
  margin: 4px 8px;
}

.nav-right > div {
  /* border: 1px solid red; */
  margin-left: 16px;
}

.nav-right > div > button {
  background: transparent;
  color: white;
  padding: 0px;
  border: 0px;
  font-size: medium;
  white-space: nowrap;
}

.sidebar-nav {
  transition: width 300ms;
  display: flex;
  flex-direction: row;
}

.sidebar-item {
  display: flex;
  flex-direction: column;
  margin: 12px 0px;
}

.sidebar-item:first-child {
  margin-top: 0px;
  margin-bottom: 12px;
}

.sidebar-item-name {
  font-size: 10px;
}

.txnIcon {
  fill: #dddddd;
  height: 24px;
  width: 24px;
}

.details-column {
  width: 20%
}
